#ds-topic{
    font-size: 40px;
}
.ds-color{
    position: relative;
}
.color-container{
    top: 0;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.color-wrapper{
    position: relative;
    display: grid;
    grid-template-columns: flex;
    margin-top: 80px;
}
.color-sets{
    position: absolute;
    right: 0;
}
.color-sets p{
    font-size: 14px;
}
.color-block{
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 440px;
    height: 60px;
    background: #E85947;
    color: white;
    border-radius: 1.4em;
    padding: 20px;
    margin-top: 10px;
}
.color-block h3{
    position: absolute;
    top: 0;
    left: 20px;
}
.color-block p{
    position: absolute;
    bottom: 0;
    left: 20px;
}
.color-sample-wrapper{
    display: block;
    gap: 30px;
}
.color-button{
    margin: 10px;
    border: none;
    width: 140px;
    height: 40px;
    border-radius: 50em;
    background: #E85947;
    color: white;
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}
.color-button:hover{
    box-shadow:  1px 4px 12px #a8a8a8 ;
    font-weight: bolder;
}
.color-dropdown {
    margin: 10px;
    position: relative;
    display: inline-block;
    padding: 8px 24px 8px 24px;
    border-radius: 50em;
    background: #E85947;
    color: white;
    z-index: 20;
}
.color-dropdown span{
    text-align: center;
    vertical-align: middle;
}
.color-dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow:  1px 4px 12px #a8a8a8 ;
    border-radius: 1.4em;
    padding: 8px 24px 8px 24px;
    z-index: 1;
    margin: 10px 0px 0 -20px;
}
.color-dropdown:hover .color-dropdown-content {
    display: block;
    z-index: 1;
}
.color-dropdown-content-select:hover{
    font-weight: 400;
    background-color: #E85947;
}
.color-dropdown-content a {
    text-decoration: none;
    display: block;
    padding: 8px 24px 8px 24px;
}
.color-dropdown-content a:hover {
    background-color: #E85947;
    color: white;
    border-radius: 50em;
    font-weight: bolder;
}
form{
    margin: 10px;
    width: 160px;
    height: 40px;
}
input{
    width: 320px;
    height: 36px;
    border-radius: 50em;
    text-align: center;
    border: #E85947 1px solid;
    font-family: "Poppins", sans-serif;
}
input:focus{
   background-color: #ffeee9;
   outline-width: 0;
}
.color-checkbox-group{
    position: relative;
    display: flex;
    gap: 44px;
}
input[type="checkbox"] {
    /* width: 3em;
    height: 3rem; */
    width: 4.7em;
    height: 2.14em;
    accent-color: #E85947 ;
    vertical-align: middle;
}
#color-round-radio{ 
    width: 20px;
    height: 20px;
    appearance: none;
    -webkit-appearance: none;
    margin: 18px 0 0 -60px;
}
#color-round-radio:focus{ 
    background-color: #E85947;
}
/* https://danklammer.com/articles/simple-css-toggle-switch/ */
.toggle {
    margin-top: 12px;
    appearance: none;
    -webkit-appearance: none;
    width: 1.8em;
    height: 1.8em;
    background-color: #ffcac3;
  }
.toggle-container{
    position: relative;
    height: 10px;
}
.toggle:before {
    position: relative;
    content: "";
    display: block;
    position: absolute;
    width: 2em;
    height: 2em;
    white-space: nowrap;
    background: #fff;
    color: #fff;
    border-radius: 1.5em;
    transition: transform cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

.toggle:checked {
    background-color: #E85947;
}
.toggle:checked:before {
    transform: translateX(2.5em);
}
.color-cards{
    position: relative;
    display: flex;
    gap: 30px;
}
.color-card-type1{
    position: relative;
    background-color: #efefef;
    border-radius: 1.4em;
    height: 200px;
    width: 200px;
    height: 200px;
}
.color-card-type1-header{
    position: absolute;
    background-color: #E85947;  
    width: 200px;
    top: 0;
    left: 0;
    border-radius:1.4em 1.4em 0 0 ;
}
.color-card-type1-header h3{
    color:white;
    padding:0 20px 0 20px;
}
.color-card-type1-body{
    position: absolute;
    top: 70px;
    left: 20px;
}
.color-card-type1-bottom{
    position: absolute;
    bottom: 42px;
    left: 20px;
    font-size: 12px;
}
.color-card-type2{
    position: relative;
    background-color: #efefef;
    width: 240px;
    height: 60px;
    border-radius: 1.4em;
    padding: 8px 8px 8px 8px;
}
.color-card-type2-top{
    position: absolute;
    background-color: #E85947;
    width: 3em;
    height: 3em;
    border-radius: 50em;
    padding: 6px;
    text-align: center;
    vertical-align: middle;
}
.color-card-type2-top h3{
    text-align: center;
    margin: 8px auto auto auto;
    color: white;
}
.color-card-type2-body{
    position: absolute;
    left: 80px;
    font-weight: bolder;
}
.color-card-type2-bottom{
    position: absolute;
    margin: 8px auto auto auto;
    right: 20px;
    font-size: 12px;
    vertical-align: middle;
}
.typo-wrapper{
    margin: 80px 0 0 0;
}
.typo-group h2{
    font-size: 64px;
    font-family: "Rubik", sans-serif;
    margin: 0;
}
.typo-chip{
    padding: 6px 18px 6px 18px;
    background-color: #E85947;
    color: rgb(255, 255, 255);
    border-radius: 50em;
    font-size: 13px;
}
#typo-explanation{
    width: 60%;
}
.typo-box-wrapper{
    position: relative;
    display: flex;
}
.typo-box{
    position: relative;
    display: flex;
    background-color: #efefef;
    width: 160px;
    height: 160px;
    margin: 20px 40px 40px 0;
    padding: 10px;
    border-radius: 1.4em;
}
#typo-box-top{
    position: absolute;
    display: flex;
    top: 0;
    left: 20px;
    font-size: 12px;
}
#typo-box-mid{
    position: relative;
    display: flex;
    vertical-align: middle;
    text-align: center;
    margin: auto;
}
#typo-box-bottom{
    position: absolute;
    bottom: 0;
    left: 20px;
    font-size: 12px;
}
.layout-container{
    position: relative;
    left: 86px;
    top: -40px;
}



/* NEW VERSION AUG 17, 2024--Brann*/ 

.ds-intro p{
    width: 68%;
}
#ds-title{
    margin-top: 100px;
    font-size: 32px;
}
/* .color-palette-container{
    border: #E85947 0.5px solid;
} */
.color-palette-main-wrapper{
    /* border: #E85947 0.5px solid; */
    border-radius: 0.8em;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(6, 1fr);
    min-height: 500px;
}
.color-palette-main{
    padding: 16px 16px 16px 22px;
    /* border: #47a8e8 0.5px solid; */
}
.color-palette-main h2{
    font-size: 56px;
    line-height: 64px;
    font-weight: 400;
    margin: auto 10px 6px auto;
}
.color-palette-main p{
    font-size: 14px;
    line-height: 16px;
    margin: auto 10px auto auto;
    vertical-align: middle;
}
.color-palette-main:first-child{
    grid-column: 1/5;
    grid-row: 1/6;
    border-radius: 0.8em 0 0 0;
}
.color-palette-main:nth-child(2){
    grid-column: 5/8;
    grid-row: 1/4;
}
.color-palette-main:nth-child(3){
    grid-column: 8/-1;
    grid-row: 1/4;
    border-radius: 0 0.8em 0 0;
}
.color-palette-main:nth-child(4){
    grid-column: 8/-1;
    grid-row: 4/6;
}
.color-palette-main:nth-child(5){
    grid-column: 5/8;
    grid-row: 4/6;
}
.color-palette-main:nth-child(6){
    border-radius: 0 0 0 0.8em;
}
.color-palette-main:nth-child(14){
    border-radius: 0 0 0.8em 0;
}
.color-palette-main:nth-child(n+6){
    display: flex;
}
.color-palette-main:nth-child(n+6) p{
    display: flex;
    align-items: center;
    margin: 0 0px 0 0;
}
.color-palette-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
}
.color-palette-box{
    /* border: #005eff 0.5px solid; */
    border-radius: 0.8em;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 0px;
}
.color-palette-display{
    /* border: #00ff44 0.5px solid; */
    padding: 10px;
    font-size: 12px;
}
.color-palette-display:first-child{
    grid-column: 1/2;
    grid-row: 1/5;
    border-radius: 0.8em 0 0 0.8em;
}
.color-palette-display:not(:first-child){
    grid-column: 2/-1;
    min-height: 40px;
}
.color-palette-display:nth-child(2){
    border-radius: 0 0.8em 0 0;
}
.color-palette-display:nth-child(5){
    border-radius: 0 0 0.8em 0;
}
.color-palette-display p{
    line-height: 16px;
    margin-left: 10px;
}
/* .color-palette-display p:nth-child(2){
    font-size: 12px;
} */
.color-palette-describtion h4{
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    margin: 30px 0 -10px 0;
}
.color-varient-sets{
    /* border: #999c00 0.5px solid; */
    display: grid;
    gap: 14px;
}
.color-varients{
    /* border: #009c37 0.5px solid; */
    border-radius: 0.8em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.color-varient{
    /* border: #43ab67 0.5px solid; */
    max-height: 40px;
}
.color-varient:first-child{
    border-radius: 0.8em 0 0 0.8em;
}
.color-varient:last-child{
    border-radius: 0 0.8em 0.8em 0;
}
.color-varient p{
    font-size: 14px;
    line-height: 40px;
    vertical-align: middle;
    margin: auto auto auto auto;
    text-align: center;
}
.color-gradient-sets{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}
.color-gradient{
    min-height: 200px;
    padding: 20px;
    border-radius: 0.8em;
}
.color-sample-meaning{
    display: flex;
    justify-content:space-between;
    gap: 30px;
    margin: 30px 0 30px 0;
}
.color-samples{
    margin: auto;
    vertical-align: middle;
    width: 160px;
    height: 40px;
    border-radius: 0.8em;
}
.color-samples p{
    text-align: center;
    vertical-align: middle;
    margin: auto;
    line-height: 40px;
    font-size: 12px;
    font-weight: 600;
}
.color-example-ratio{
    position: relative;
    display: block;
    text-align: center;
    margin: auto;
}
.color-use{
    font-size: 14px;
    position: absolute;
    top: 25%;
    left: 33%;
    margin: auto;
    /* transform: translate(50%, 50%); */
}
@media screen and (min-width:1600px) {
    .color-use{
        top: 32%;
        left: 36%;
    }
}
#color-sample-image{
    margin: auto;
    text-align: center;
    position: relative;
    display: block;
    width: 60%;
}
.icons-artworks-set{
    /* border: #009c37 0.5px solid; */
    border-radius: 0.8em;
    min-height: 360px;
}
.icon-container{
    /* border: #da4a4a 0.5px solid; */
    margin: 0px 0 0 0;
}
.icons-wrapper{
    /* border: #1919f8 0.5px solid; */
    display: grid;
    grid-template-columns: 1fr 2fr;
}
.icons-r{
    /* border: #f819e6 0.5px solid; */
    position: absolute;
    display: flex;
    text-align: right;
    justify-content: end;
    padding: 30px;
    margin-top: 40px;
    right: 0;
    width: 50%;
}
.icons-l{
    /* border: #19f8b5 0.5px solid; */
    position: relative;
    padding: auto;
    text-align: center;
    margin: 50px auto auto auto;
}
.icon-usage1{
    position: relative;
    background-color: #ffcfc8 ;
    width: 144px;
    height: 144px;
    border: #f47766 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    /* border: #ffcfc8  12px solid; */
}
.icon-usage-inner{
    width: 120px;
    height: 120px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon-title{
    position: relative;
    margin: auto auto auto auto;
    padding: auto;
    /* border: #f47766 1px dashed; */
}
.icons-artworks-set p{
    margin-left: 10px;
}
.typography-sets{
    position: relative;
    border: #001eff 0.5px solid;
    border-radius: 0.8em;
}
.typography-set{
    position: relative;
    border: #91d3ff 0.5px solid;
    padding: 12px 32px 32px 12px;
    height: 280px;
    background-color: #FCFCFC;
    box-shadow:  1px 4px 12px #a8a8a8 ;
    border-radius: 0.8em;
    vertical-align: middle;
    margin: auto auto 10px auto;
}
.typography-sample{
    position: relative;
    height: 50px;
    vertical-align: middle;
    margin: auto;
}
.typography-set p{
    line-height: 16px;
}
.typography-set h4, .typography-set p{
    padding-left: 20px;
}
.typeface-display{
    /* border: #e21a00 1px solid; */
    position: relative;
}
.typeface-each-wrapper{
    position: relative;
    /* border: #E85947 1px solid; */
    display: grid;
    grid-template-columns: 1.2fr 2fr;
}
.typeface-detail-box{
    position: relative;
    display: flex;
    /* border: #0320ff 1px solid; */
    padding: 10px 30px 10px 30px;
    margin: 15px 0 15px 0;
    align-items: center;
    /* max-height: 260px; */
}
.typeface-detail-box:first-child{
    background-color: #ededed;
    border-radius: 0.8em;
}
.typeface-detail-box:nth-child(2){
    position: absolute;
    align-self: center;
    left: 50%;
}
#typeface-tag{
    margin-top: -30px;
    margin-left: -20px;
    margin-bottom: -10px;
}
.typeface-textbox{
    position: relative;
    /* border: #f47766 1px solid; */
    background-color: #ffffff;
    outline: #ffcfc8 1.5em solid;
    border: #f47766 1px solid;
    padding: auto;
    margin: auto;
    position: relative;
}
.typeface-textbox p{
    /* border: #f7df0e 1px solid; */
    position: relative;
    background-color: white;
    font-family: "Istok Web", sans-serif;
}
.icon-sample-button{
    /* border: #E85947 solid 1px;  */
    display: flex;
    width: 100px;
    padding: 6px 24px 6px 24px;
    background-color: #E6E6E6;
    border-radius: 0.8em;
    gap: 6px;
}
#product-text{
    font-family: "Istok Web", sans-serif;
    vertical-align: middle;
    text-align: left;
    margin: 0 auto 0 auto;
    left: 0;
    box-shadow: -12px 0px 0px 0px #F58522;
    height: 24px;
    padding-bottom: 2px;
}
.icon-button{
    margin: auto;
    display: flex;
    padding: 4px;
    width: 120px;
}
#icon-sample{
    width: 20px;
    height: 20px;
    outline: #ffa497 4px solid;
    vertical-align: middle;
    margin: auto 0 auto 0;
}
.icon-title{
    width: 100%;
    display: flex;
    gap: 12px;
    justify-content: center;
}
.typeface-text p{
    margin: 0.5em;
    max-width: 500px;
}
.dot-rt{
    position: absolute;
    width: 0.4em;
    height: 0.4em;
    background-color: #f47766;
    border-radius: 0.1em;
    right: -0.2em;
    top: -0.2em;
}
.dot-rb{
    position: absolute;
    width: 0.4em;
    height: 0.4em;
    background-color: #f47766;
    border-radius: 0.1em;
    right: -0.2em;
    bottom: -0.2em;
}
.dot-lt{
    position: absolute;
    width: 0.4em;
    height: 0.4em;
    background-color: #f47766;
    border-radius: 0.1em;
    left: -0.2em;
    top: -0.2em;
}
.dot-lb{
    position: absolute;
    width: 0.4em;
    height: 0.4em;
    background-color: #f47766;
    border-radius: 0.1em;
    left: -0.2em;
    bottom: -0.2em;
}
.tags{
    /* border: #ff00cc 0.5px solid; */
    width: 100%;
    padding: 20px;
}
#typeface-tag .tag{
    background-color: white;
    font-size: 12px;
}
.typeface-table{
    margin: 60px 0 40px 0;
}
.typeface-table-each{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    text-align: center;
    line-height: 18px;
    border-radius: 0.8em;
}
.typeface-table-each:first-child{
    font-weight: bold;
    margin-bottom: 20.7px;
}
.typeface-table-each p:first-child{
    font-weight: bold;
}
.typeface-table-each:nth-child(odd){
    background-color: #ededed;
}


.layout-compo-container{
    /* border: #E85947 0.5px solid; */
}
.format-layout-wrapper-desktop{
    border: #4757e8 0.5px solid;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}
.layout{
    border: #ad47c6 0.5px solid;
    padding: 20px;
    border-radius: 0.8em;
    min-height: 300px;
}
.format-layout-wrapper-others{
    border: #4757e8 0.5px solid;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
}
.format-layout-wrapper-component{
    border: #4757e8 0.5px solid;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
}
.component{
    border: #ad47c6 0.5px solid;
    padding: 20px;
    border-radius: 0.8em;
    min-height: 120px;
}

#layout_format{
    width: 100%;
}
#component_layout_format{
    position: relative;
    width: 100%;
}