/* border: #494949 1px solid; */
body{
    font-family: "Poppins", sans-serif;
    margin: 24px 138px auto 138px;
    color: #494949;
}
body#homepage-layout p{
    font-size: 15px;
}
.text-wrapper{
    text-align: left;
    color: #34363C;
    width: 80%;
    animation: aLittleJump 2s;
}
#landing_title{
    position: relative;
    margin: -20px auto -20px auto;
    font-size: 90px;
    font-weight: 800;
    line-height: 94px;
    z-index: 20;
    font-family: "Geologica", sans-serif;
}
#landing_intro{
    position: relative;
    font-size: 22px;
    font-weight: 400;
    line-height: 42px;
    z-index: 20;
}
@keyframes aLittleJump {
    from {
        transform: translateY(-12%);
    } to {
        transform: translateY(0);
    }
}
.banner-avartar{
    display: flex;
    justify-content: center;
    margin: auto;

}
@keyframes aLittleSlide {
    from {
        transform: translateX(-12%);
    } to {
        transform: translateX(0);
    }
}
#brann_avartar{
    position: absolute;
    top: 20px;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    width: 32%;
    opacity: 0.9;
    z-index: 100;
    animation: aLittleSlide 2s;
}
#cloud{
    position: absolute;
    top: 90px;
    z-index: 1;
    animation: slide 30s infinite;
}
@keyframes slide {
    from{
        opacity: 0.3;
        transform: translateX(0);
    } to {
        transform: translateX(1100px);
        opacity: 0.8;
    }
}
.tags{
    display: inline-flex;
    gap: 10px;
}
.tag{
    padding: 6px 18px 6px 18px;
    background-color: rgba(79, 132, 231, 0.1);
    color: rgb(80, 80, 80);
    border-radius: 50em;
    font-size: 15px;
}
.project-container{
    margin: 80px -138px auto -138px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
}
.stories{
    position: relative;
}
.story{
    position: relative;
}
#Research{
    margin-top: 0px;
    background-color: #e4e4e4;
    position: relative;
    left: 0;
    right: 0;
    margin-right: -138px;
    margin-left: -138px;
    padding: 160px 138px 80px 138px;
}
#Testing-Wireframes{
    /* display: flex; */
    margin-top: 0px;
    background-color: #5e5e5e;
    position: relative;
    left: 0;
    right: 0;
    margin-right: -138px;
    margin-left: -138px;
    padding: 80px 138px 80px 138px;
}
.mobile{
    display: none;
}
@media screen and (max-width:1100px){
    body#homepage-layout{
        margin: 24px 24px auto 24px;
    }
    p{
        font-size: 14px;
    }
    .text-wrapper{
        width: 100%;
    }
    .banner-container{
        display: block;
        margin: auto -24px 40px -24px;
    }
    #brann_avartar, #cloud{
        display: none;
    }
    #landing_title{
        margin: -20px auto -20px auto;
        font-size: 48px;
        font-weight: 2000;
        line-height: 62px;
    }
    #landing_intro{
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
    }
    .project-container{
        display:block;
    }
    .project-card:nth-child(even){
        grid-column: 1/-1;
    }
    .tag{
        font-size: 12px;
        padding: 3px 10px 3px 10px;
        background-color: rgba(79, 132, 231, 0.1);
        color: rgb(80, 80, 80);
        border-radius: 50em;
    }
    .project-container{
        margin: 20px auto;
    }
    .desktop{
        display: none;
    }
    .mobile{
        display: block;
    }
}