a{
    text-decoration: none;
    color: #494949;
}
.breadcrumb{
    display: flex;
    gap: 10px;
}

/* PROJECT-BRIEF */
#breif-top{
    position: relative;
    transition: left 1s ease-in-out;
    padding-bottom: 80px;
}
.project-brief-container{
    width: 40%;
}
.project-brief-container h2{
    font-size: 38px;
}
.project-brief-container p{
    margin-top: -20px;
}


/* PROJECT-COMPO */
.componentA {
    position: relative;
    display: flex;
    border-radius: 0.8em;
    outline: solid 12px rgb(253, 253, 253);
    box-shadow:  10px 20px 20px #f0f0f0 ;
    padding: 10px;
    margin-top: 80px;
}
.componentA img{
    position: relative;
    width: 60%;
    border-radius: 0.8em;
    z-index: 200;
}
.c-a-words{
    position: relative;
    color: white;
    vertical-align: middle;
    margin: auto auto auto auto;
    z-index: 50;
}
.c-a-words h2{
    font-size: 70px;
    font-weight: 500;
    line-height: 80px;
    margin-bottom: -20px;
}
.c-a-words p{
    font-size: 24px;
    font-weight: 600;
    line-height: 80px;
    margin-left: 4px;
    margin-bottom: 20px;
}
.compo-a2-bg{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0.8em;
    z-index: 40;
}
.componentB{
    margin-top: 160px;
    width: 200px;
    height: 200px;
    border-radius: 0.8em;
    padding: 24px;
    text-align: center;
}
.componentB h2{
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    margin-top: 18px;
    margin-bottom: -18px;
}
.componentB img{
    text-align: center;
    width: 100%;
    margin: -80px auto auto auto;
    box-sizing: border-box;
    scale: 1.1;
}
.componentB:hover img{
    scale: 1.3;
    animation: aLittleZoom 2s;
}
.componentC{
    display: grid;
    grid-template-columns: 0.8fr 1.5fr;
    margin: 120px 0 200px 0;
}
.componentC2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto auto 200px -220px;
}

@keyframes aLittleShake {
    from {
        transform: translate(-10px, 10px)
    } to {
        transform: translate(0px, 0px)
    }
}
.c-c-img{
    display: flex;
    display: flex;
    margin-top: 50px;
    /* background-color: rgb(0,106,255); */
    border-radius: 100em;
    width: 300px;
    height: 300px;
}

.c-c-img img{
    display: flex;
    width: 150%;
    text-align: center;
    margin: 40px auto auto 80px;
    animation: aLittleShake 3s infinite;
}
.c-c-text{
    vertical-align: middle;
    margin: -20px auto auto 280px;
    text-align: left;
    width: 60%;
}
.c-c-wraper h2{
    font-size: 70px;
    font-weight: 500;
    margin-bottom: -18px;
    line-height: calc(70px*1.2);
    letter-spacing: -0.4px;
}
.c-c-wraper p{
    margin-top: calc(24px*1.5);
    font-size: 24px;
    font-weight: 400;
    line-height: calc(24px*1.5);
    margin-left: 4px;
}
@keyframes aLittleZoom {
    from {
        scale: 1.1;
    } to {
        scale: 1.3;
    }
}
.amigo-icons{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 40px;
    margin: auto auto 100px auto;
}
.amigo-icon{
    display: grid;
    grid-template-columns: 0.6fr 1.4fr;
    background-color: #7d42f9;
    color: white;
    border-radius: 0.8em;
    box-shadow: 10px 10px 20px rgb(216, 216, 216);
    padding: 20px;
    height: 100px;
}
.amigo-icon:hover{
    animation: 1.8s aLittleZoom;
    scale: 1.14;
}
.amigo-icon img{
    height: 40%;
    justify-content: center;
    vertical-align: middle;
    margin: auto auto auto auto;
}
.amigo-icon p{
    width: 80%;
    text-align: left;
    vertical-align: middle;
    margin: auto;
    font-size: 14px;
}
@keyframes aLittleZoom {
    0% {
        scale: 1;
    }
    100%{
        scale: 1.14;
    }
}
.cos-com-b{
    display: flex;
    gap: 12px;
    justify-content: center;
    margin: 80px 0 0 0;
}
.componentE{
    margin-top: 160px;
    position: relative;
    display: flex;
}
.c-e-prototype{
    position: relative;
    left: -220px;
    border-radius: 0 0.8em 0.8em 0;
    height: 600px;
    background-color: #494949;
    padding: 20px 0px 20px 138px;
    z-index: 200;
    display: flex;
}
.desktop-prototype{
    position: relative;
    margin: 0 -138px 0 -138px;
    padding: 20px 138px 20px 138px;
    background-color: #494949;
    display: flex;
    justify-content: space-around;
    vertical-align: middle;
}
.c-e-prototype-desktop{
    width: 100%;
    position: relative;
    left: -220px;
    right: 0;
    border-radius: 0 0.8em 0.8em 0;
    height: 600px;
    background-color: #494949;
    padding: 20px 0px 20px 138px;
    z-index: 200;
    display: flex;
}
.v-text-container{
    display: block;
    padding-top: 120px;
}
#proto-v-text{
    position: relative;
    color: rgb(188, 210, 255);
    /* margin-left: -30px; */
    /* writing-mode: tb-rl;
    transform: rotate(180deg); */
    font-size: 80px;
    vertical-align: middle;
}
#prototype{
    position: relative;
    border: none;
    left: -138px;
    margin-top: 0px;
    margin-left: 130px;
    width: 400px;
    height: 600px;
    z-index: 200;
    margin-right: 20px;
    background-color: none;
}
#prototype-v2{
    position: relative;
    border: none;
    /* left: -138px; */
    transform: translateX(60%);
    margin-top: 0px;
    margin-left: 130px;
    width: 400px;
    height: 600px;
    z-index: 200;
    /* margin-right: 20px; */
    background-color: none;
}
#prototype-desktop{
    position: relative;
    border: none;
    right: 0;
    margin-top: 0px;

    width: 1200px;
    height: 600px;
    z-index: 200;
    background-color: none;
}
.c-e-frame{
    position: relative;
    height: 600px;
    right: -138px;
    margin-left: -480px;
    background-color: #494949;
    padding: 20px 150px 20px 20px;
    border-radius: 0.8em 0 0 0.8em ;
    overflow-x: hidden;
    z-index: 300;
}
#overall{
    width: 220%;
}

#amigo-finding-no{
    display: grid;
    grid-template-columns: 0.1fr 2fr;
    gap: 40px;
    width: 60%;
    margin-top: 20px;
}
#amigo-finding-no h4{
    background-color: #708E52;
    color: white;
    text-align: center;
    width: 62px;
    border-radius: 50em;
    margin: auto;
    vertical-align: middle;
}
#amigo-finding-no b{
    color: #7d42F9;
}
.talk svg{
    width: 2%;
    margin-left: 10px;
    padding: auto 30px auto 30px;
}
.talk svg:hover{
    width: 2%;
    margin-left: 10px;
    fill: #E8005D;
}
.intern{
    background-color: #dc5e0a;
    color: white;
    margin: 80px 0px 0 -138px;
    padding: 40px 0 80px 138px;
    border-radius: 0 50em 50em 0;
}
.intern2{
    background-color: #f4ab0c;
    color: rgb(69, 69, 69);
    margin: 80px -138px 0 200px;
    padding: 40px 120px 80px 300px;
    border-radius: 50em 0 0 50em;
}
.intern h2, .intern2 h2{
    font-size: 60px;
}
.nl-header{
    margin: 60px 40px 0 40px;
    color: white;
    overflow: hidden;
}
.nl-header h1{
    line-height: 20px;
}
#bold{
    font-weight: bolder;
}
.nl-header p{
    text-align: center;
}
#bn-natlib{
    transform: translateX(15%);
    position: relative;
    width: 82%;
    text-align: center;
    margin: 48px 0 48px 0;
}
.nl-topics{
    display: flex;
    justify-content: space-between;
}
.nl-topic{
    display: grid;
    grid-template-rows: 0.5fr 1fr;
}
.nl-topic h4{
    margin-top: 16px;
    text-align: left;
}
.nl-topic p{
    text-align: left;
}
.nl-body{
    position: relative;
    margin: 42px;
    justify-content: center;
}
.nl-body img{
    text-align: center;
    margin: auto;
}
.scope-tools {
    margin-top: 68px;
}
.scope-tools h2{
    text-align: left;
    font-size: 38px;
}
.scope-tools p{
    text-align: left;
    width: 30%;
}
.detail h4{
    text-align: left;
    line-height: 26px;
    font-size: 20px;
    margin-bottom: 0px;
}
.detail p{
    text-align: left;
    width: 30%;
}
#nl-detail1{
    margin: -180px 0 0 120px;
}
#nl-detail2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0px 0 30px 120px;
}
#nl-detail2 p{
    width: 70%;
}


@media screen and (min-width:1800px) {
    .componentE{
        margin-top: 320px;
    }
    #c-a-content img{
        width: 44%;
    }
    .c-e-overall img{
        width: 130%;
        text-align: right;
    }
}
@media screen and (max-width:1100px){
    .componentA{
        display: block;
    }
    #breif-top{
        transition: left 1s ease-in-out;
        padding-bottom: 40px;
    }
    .project-brief-container{
        width: 100%;
    }
    .componentA {
        display: block;
        border-radius: 0.8em;
        outline: solid 12px rgb(253, 253, 253);
        box-shadow:  10px 20px 20px #f0f0f0 ;
        padding: 12px;
        text-align: center;
    }
    .componentA img{
        width: 100%;
        border-radius: 0.8em;
    }
    .c-a-words{
        color: white;
        vertical-align: middle;
        margin: auto auto auto auto;
    }
    .c-a-words h2{
        font-size: 38px;
        font-weight: 400;
        line-height: 42px;
    }
    .c-a-words p{
        font-size: 15px;
        font-weight: 600;
        line-height: 62px;
        margin-left: 0px;
        margin-bottom: 0px;
    }
    .cos-com-b{
        display: block;
        text-align: center;
        margin: auto auto auto 60px;
        scale: 0.9;
    }
    .componentB{
        margin-top: 100px;
    }
    .componentC{
        margin-top: -20px;
        display: block;
    }
    .componentC img{
        width: 80%;
        text-align: center;
    }
    .c-c-text{
        vertical-align: middle;
        margin: auto auto auto auto;
        text-align: left;
        width: 100%;
    }

    .componentC h2{
        font-size: 38px;
        font-weight: 400;
        line-height: 42px;
    }
    .componentC p{
        margin-top: 40px;
        font-size: 15px;
        font-weight: 600;
        line-height: 32px;
    }
    .c-e-prototype{
        display: none;
    }
    #overall{
        width: 100%;
    }
    #Research{
        display: none;
    }
    .project-navs{
        display: none;
    }
}