#profile-picture{
    display: flex;
    align-items: center;
    text-align: center;
    margin: 10px auto auto auto;
    width: 15%;
    border-radius: 50%;
    filter: brightness(1.25);
}
@media screen and (max-width:1100px){
    #profile-picture{
        width: 50%;
    }
}
.aboutme-frames{
    position: relative;
    display: grid;
    margin-top: 62px;
    justify-content: center;
    
}
.aboutme-frames img{
    width: 80%;
    text-align: center;
    margin: auto;
}
.aboutme-frames h1{
    font-size: 24px;
    margin: 10px auto auto auto;
}
.aboutme-frames h2{
    font-size: 140px;
}
.aboutme-frames h4{
    font-size: 24px;
}
.aboutme-frames p{
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    
}
.about-span{
    display: inline-flex;
    justify-content: center;
    margin: 20px auto 100px auto;
}
#brann{
    font-family: "Rubik Mono One", monospace;
    font-weight: 400;
    rotate: -18deg;
    margin: auto 65px auto 65px;
    color: rgb(134, 134, 134);
}
#brann:hover{
    animation: text-jump 1s;
    color: #355ded;
}
#ab-detail{
    display: none;
}
#ab-detail h4{
    font-size: 30px;
    font-weight: bold;
    text-align: left;
}
#ab-detail p{
    text-align: left;
    width: 260px;
}
#about-detail:hover #ab-detail{
    animation: cardDetail 2s;
    display: block;
    color: #355ded;
}
@keyframes text-jump {
    0%{
        transform: translateX(-10%);
    }
    100%{
        transform: translateX(0%);
        color: #355ded;
    }
}
#ab-foot{
    margin-top: 100px;

}
#ab-foot:hover{
   animation: slide 1s;
}
.about-small-detail{
    display: flex;
    vertical-align: middle;
    margin: auto;
    gap: 30px;
    width: 100%;
}
#ab-sd-left{
    justify-content: start;
}
#ab-sd-right{
    justify-content: end;
}
.about-small-detail p{
    padding: 20px;
    border-radius: 5em;
}
#ab-det-yellow{
    background-color: #555555;
    color: white;
}
#ab-det-red{
    background-color: #d2d2d2;
}
.intro-self p{
    font-size: 18px;
    line-height: 28px;
    padding: 1em;
    text-align: left;
}
#mobile-aboutme{
    display: none;
}
#me-title{
    display: inline-flex;
}
#me-title h4{
    margin-top: -3px;
    margin-left: 6px;
}
#me-title p{
    text-align: left;
}
.funfact{
    border: 1px solid grey;
    border-radius: 0.5em;
    vertical-align: middle;
    justify-content: right;
    padding: 3em;
    margin: auto;
}
.funfact p{
    text-align: left;
}
img {
    z-index: 2;
}

#hello{
    vertical-align: top;
    margin: 42px 0 0 0;
}
.introduction{
    width: 90%;
    margin-bottom: 42px;
}
.mb-about-group h3{
    background-color: #0033ff;
    color: white;
    text-align: center;
    padding: 3px 12px 3px 12px;
    border-radius: 50em;
    width: 120px;
}

.aboutme-contianer{
    margin-top: 160px;
}
.aboutme-header-wrap{
    /* border: rgb(157, 255, 0) 2px solid; */
    display: flex;
    max-width: 800px;
}
.am-h-r{
    /* border: red 2px solid; */
    min-width: 400px;
    vertical-align: middle;
    margin: 20px auto 0 auto;
}
.bk-story-container{
    margin: 80px auto 80px auto;
    background-color: #355ded;
    width: 100%;
    min-height: 600px;
    border-radius: 24px;
}
.bk-brief-container{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 24px;
    margin: auto auto 80px auto;
}
.bk-icon{
    background-color: #9e9e9e;
    height: 60px;
    width: 60px;
    border-radius: 100em;
}
.bk-brief{
    display: flex;
    gap: 24px;
}
.aboutme-contianer p{
    font-size: 18px;
}
.bk-hobbies-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin: auto auto 80px auto;
}
.bk-hobbie{
    display: flex;
    gap: 24px;
}
.hob{
    border: #0033ff 1.8px solid;
    border-radius: 24px;
    width: calc(720px/2.5);
    height: calc(1280px/2.5);;
    display: grid;
    grid-template-rows: 1fr 3fr 1fr;
    padding: 24px;
}
/* .hob-detail{
    border: red solid 3px;
} */
.hob-detail:nth-child(1){
    display: flex;
    align-items: center;
    margin: auto -24px auto -24px;
    height: 100%;
    padding-left: 24px;
    justify-content: space-between;
}
.hob-detail:nth-child(2){
    background-color: #355ded;
    color: white;
    margin: auto -24px auto -24px;
    padding-left: 24px;
    padding-right: 24px;
    height: 100%;
    display: flex;
    align-items: center;
}
.hob-detail:nth-child(3){
    margin: auto -24px auto -24px;
    padding: auto 24px auto 24px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 24px;
}
.hobbie-text{
    margin-left: 80px;
}
#about-title{
    font-size: 42px;
    font-family: "Geologica", sans-serif;
}
.design-story-wrap{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    min-height: 640px;
    gap: 8px;
}
#onefrtwofr{
    grid-template-columns: 1fr 2fr;
}
.hobbie-text{
    vertical-align: middle;
    margin: auto;
    padding: 24px;
}
.design-story{
    border: #0033ff 1.8px solid;
    height: 100%;
    width: 100%;
    border-radius: 24px;
}