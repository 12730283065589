.project-template h1{
    /* border: solid rgb(223, 156, 0) 1px; */
    font-size: 72px;
    font-weight: 800;
    line-height: calc(72px*1.5);
    margin: auto;
    font-family: "DM Sans", sans-serif;
    color: #313a49;
}
.project-template h2{
    /* border: solid rgb(223, 156, 0) 1px; */
    font-size: 38px;
    font-weight: 800;
    line-height: calc(28px*1.5);
    margin: auto;
    letter-spacing: 0.6px;
    font-family: "DM Sans", sans-serif;
    color: #313a49;
}
.project-template h3{
    /* border: solid rgb(223, 156, 0) 1px; */
    font-size: 22px;
    font-weight: 400;
    line-height: calc(22px*1.5);
    margin: auto;
    letter-spacing: 0.6px;
    font-family: "DM Sans", sans-serif;
    color: #313a49;
}
.project-template h4{
    /* border: solid rgb(223, 156, 0) 1px; */
    font-size: 54px;
    font-weight: 400;
    line-height: calc(22px*1.5);
    margin: 0;
    letter-spacing: 0.6px;
    font-family: "DM Sans", sans-serif;
    color: #313a49;
}
.project-template p{
    /* border: solid rgb(255, 230, 0) 1px; */
    font-size: 17px;
    font-weight: 300;
    max-width: 690px;
    line-height: calc(18px*1.5);
    margin: 12px 0 0 0;
    color: #313a49;
}
.project-template b{
    font-weight: 500;
}
.pb-banner-texts p{
    max-width: 500px;
}
.project-template{
    margin: 0 0 120px 0;
}
.pb-banner-container{
    position: relative;
    max-width: 560px;
    overflow: hidden;
    animation: aLittleSlide 2s;
}
@keyframes aLittleSlide {
    from {
        transform: translateX(-12%);
    } to {
        transform: translateX(0);
    }
}
.pb-banner-texts{
    margin: 210px auto 180px auto;
}
.pb-chips-wrap{
    margin-top: 36px;
    display: flex;
    gap: 8px;
}
.pb-chips-wrap p{
    font-size: 16px;
}
.pb-chip{
    color: #292929;
    padding: 4px 36px 4px 36px;
    border-radius: 50em;
    background-color: rgb(229, 238, 246);
}
#cos-chip{
    background-color: #0966FF;
    color: white;
}
#cos-chip-tool{
    background-color: #313a49;
    color: white;
}
.pb-brief-wrap{
    /* border: red 1px solid; */
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 30px;
    min-height: 300px;
}
.pb-brief-ea{
    /* border: rgb(255, 208, 0) 1px solid; */
    display: grid;
    grid-template-rows: 1fr 2fr;
    min-height: 200px;
}
.pb-brief-ea:nth-child(3){
    grid-column: 1/-1;
    max-height: 120px;
    min-height: 0;
}
#pb-brief-ea-t:nth-child(2){
    max-width: 440px;
    margin: 0 auto 0 0;
    vertical-align: bottom;
}
.pb-chips-wrap:nth-child(2){
    margin-top: 0px;
    margin-bottom: 30px;
}
.pb-brief{
    margin-top: 48px;
}
.pb-brief{
    max-width: 560px;
}
.line-v{
    border-left: #ebeff4 3px solid;
    min-height: 300px;
    margin-left: 40px;
    border-radius: 2px;
}
.pd-scope-container{
    text-align: center;
    margin-top: 120px;
    margin-left: -138px;
    margin-right: -138px;
    padding: 80px 138px 80px 138px;
    background-color: #0966FF;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    color: rgb(255, 255, 255);
}
.pd-im{
    text-align: center;
    align-items: center;
}
.pd-im p{
    color: white;
    width: 300px;
    margin: auto;
}
#pd-im-st{
    color: rgb(255, 255, 255);
    font-size: 100px;
    font-weight: 400;
    letter-spacing: 1.8px;
    margin: 0;
    margin-bottom: 8px;
    line-height: 100px;
}
.pd-overview-container{
    margin-left: -138px;
    margin-right: -138px;
    padding: 120px 138px 120px 138px;
    background-color: #282c2f;
    color: white;
}
.pd-ov{
    text-align: center;
    color: white;
    width: 100%;
    height: 540px;
    background-color: white;
    border-radius: 24px;
    display: flex;
}
#big_banner{
    width: 100%;
}
#reg_banner{
    width: 50%;
    margin-top: 0px;
    margin-right: 20px;
    z-index: -1;
}
.align-right{
    position: absolute;
    right: 62px;
}
.pd-ov h2, .pd-p-l h2, .pd-p-t-top h2, .pd-branding-conmtainer h2, .p-prototype-container h2, #title-h2{
    font-size: 72px;
    font-weight: 400;
    line-height: calc(72px*1.2);
}
.pd-product-focus{
    overflow: hidden;
    position: relative;
    display: flex;
    margin-left: -138px;
    margin-right: -138px;
    padding: 120px 138px 120px 138px;
    background-color: #313a49;
    z-index: 200;
    min-height: 550px;
}
@media screen and (min-width:1600px) {
    .pd-product-focus{
        min-height: 660px;
    }
    #on-track{
        font-size: 190px;
        line-height: calc(190px*1.0);
    }
    #reg_banner{
        width: 42%;
    }
    .pd-ser-container{
        margin-top: 120px;
    }
}
#on-track{
    color: #939ba9;
    margin-left: 20px;
    font-size: 130px;
    font-weight: 800;
    line-height: calc(130px*1.0);
    transform: skew(00deg,-3deg);
}
#background-img{
    position: absolute;
    opacity: 0.8;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    /* transform: scaleX(-1); */
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
}
#middle-img{
    display: flex;
    width: 70%;
    text-align: center;
    margin: 60px auto 60px auto;
}
.pd-p-r{
    position: absolute;
    display: flex;
    background-color: #ffffff;
    border-radius: 24px;
    height: 550px;
    width: 290px;
    justify-content: center;
    align-items: center;
    right: 20%;
    transform: rotate(32deg)
}
.pd-p-r-pair1{
    position: absolute;
    display: flex;
    background-color: #ffffff;
    border-radius: 24px;
    height: 550px;
    width: 290px;
    justify-content: center;
    align-items: center;
    right: 138px;
    bottom: -20%;
}
.pd-p-r-pair2{
    position: absolute;
    display: flex;
    background-color: #ffffff;
    border-radius: 24px;
    height: 550px;
    width: 290px;
    justify-content: center;
    align-items: center;
    right: 30%;
    top: -20%;
}
.pd-p-l{
    position: absolute;
    left: 138px;
    top: 21%;
    animation: aLittleSlide 2s;
}
#pd-p-l-t{
    margin-top: 30px;
    margin-left: 32px;
}
#pd-p-l-t:nth-child(2){
    margin-top: 36px;
}

.pd-ser-container{
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 20px;
}
.pd-s-ea{
    position: relative;
    background-color: #282c2f;
    min-height: 140px;
    border-radius: 24px;
    padding: 24px;
    overflow: hidden;
}
.pd-s-ea:nth-child(1){
    grid-column: 1/3;
    grid-row: 1/3;
}
.pd-s-ea:nth-child(5){
    grid-row: 2/-1;
}
.pd-s-ea img{
    position: absolute;
    margin:auto;
    left: 0;
    right: 0;
    top: 30%;
    bottom: 0;
    text-align: center;
}
#ser-big-banner{
    position: absolute;
    text-align: right;
    margin: 100px 4em 0 auto;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32%;
}
.pd-s-ea-t{
    height: 100%;
    /* border: red 2px solid; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.pd-s-ea-t h3{
    margin: 0;
    color: white;
    font-weight: 500;
}
.pd-s-ea-t p{
    display: none;
    max-width: 340px;
    font-size: 15px;
    color: white;
}
#fts-72{
    margin: 10px 0 0 0;
    font-size: 72px;
    max-width: 280px;
    line-height: calc(72px*1);
}
.pd-premium-container{
    margin-top: 120px;
    overflow: hidden;
}
.pd-p-t-top {
    margin-top: 6px;
    transform: translateX(20%);
}
.pd-p-t-top p{
    margin-top: 36px;
}
.pd-p-product{
    margin-top: 60px;
    margin-bottom: 60px;
    background-color: #bfbfbf;
    width: 100%;
    min-height: 120px;
    border-radius: 24px;
}
.pd-p-t-bottom{
    display: grid;
    grid-template-columns: 1fr 1.2fr 1fr;
    gap: 60px;
}
.pd-p-t-b-m{
    padding-left: 30px;
    padding-right: 30px;
}

.pd-branding-conmtainer{
    overflow: hidden;
    position: relative;
    margin-top: 120px;
    margin-left: -138px;
    margin-right: -138px;
    padding: 120px 138px 120px 138px;
    background-color: #282c2f;
    z-index: 200;
    min-height: 550px;
    text-align: left;
}
.pd-b-wrap{
    position: relative;
    gap: 30px;
}
.pd-b-ea{
    /* border: white 1px solid; */
    background-color: #494949;
    min-height: 200px;
    border-radius: 24px;
}

.p-prototype-container{
    overflow: hidden;
    position: relative;
    margin-left: -138px;
    margin-right: -138px;
    padding: 120px 138px 120px 138px;
    background-color: #EEEEEE;
    overflow: hidden;
    min-height: 380px;
}

.pr-mapping-container{
    margin-top: 120px;
    text-align: center;
}
.pr-research-wrap{
    margin-top: 120px;
}
.pr-r-ea{
    margin-top: 120px;
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    gap: 60px;
}
.pr-r-ea-t{
    overflow-y: scroll;
    scrollbar-width: none;
    max-height: 560px;
}
.pr-r-ea-img{
    /* background-color: #cacaca; */
    margin: auto;
    vertical-align: middle;
    width: 100%;
    border-radius: 24px;
    padding: 100px 30px 100px 30px;
}
.pr-r-ea-col{
    display: grid;
    grid-template-rows: 0.5fr 1.5fr;
}
.pr-r-ea-col-img{
  margin-bottom: 36px;
}
.pr-r-ea-col-t{
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.pr-r-ea-3col{
    margin-top: 120px;
    margin-bottom: 120px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;
}
.pr-research-users-wrap{
    overflow: hidden;
    position: relative;
    margin-left: -138px;
    margin-right: -138px;
    padding: 120px 138px 120px 138px;
    background-color: #EEEEEE;
    overflow: hidden;
}

.p-pt-wrap{
    position: relative;
    display: flex;
    align-items: center;
    height: 360px;
}
.p-prototype-container:hover{
    background-color: #313a49;
    animation: colorChange 3s;
}
@keyframes colorChange {
    from{
        background-color: #EEEEEE;
        color: #282c2f;
    }
    to{
        background-color: #313a49;
        color: white;
    }
}
.p-prototype-container:hover .p-pt-wrap-l h2{
    color: white;
    animation: colorChange 3s;
}
.p-pt-wrap-l{
    position: absolute;
    display: flex;
    flex-direction: column;
}
.p-pt-wrap-l a{
    text-align: center;
}
.p-pt-wrap-r{
    position: absolute;
    margin: auto;
    top: -140px;
    right: -158px;
    left: 46%;
    min-height: 500px;
    padding: 1em;
}

.clickable-wrap{
    margin-top: 120px;
    justify-content: center;
    display: flex;
    gap: 60px;
}
.clickable{
    font-size: 18px;
    padding: 14px 48px 14px 48px;
    background-color: #0966FF;
    border-radius: 100em;
    color: white;
}
#gauge-click{
    background-color: #E02434;
}
.clickable:hover{
    animation: aLittleJump 2s;
}
@keyframes aLittleJump {
    from {
        transform: translateY(-12%);
    } to {
        transform: translateY(0);
    }
}
#research-header{
    font-size: 42px;
    line-height: calc(42px*1.2);
    margin-bottom: 18px;
}
#rs-icon{
    width: 20%;
}
@media screen and (max-width:1200px) {
    .project-template{
        display: none;
    }
}



#gauge-chip{
    background-color: #E02434;
    color: white;
}