.ux-r-intro{
    width: 60%;
}
.ResearchA{
    margin: 80px 0 0px 0;
 }
.ResearchA img{
   margin: 40px 0 40px 0;
}
.ResearchB{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;
    margin: 0px 0 80px 0;
}
.ResearchC{
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin: 0px 0 80px 0;
}
.ResearchC img{
    width: 24%;
}

.cos-sketch-block{
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 30px;
}
.cos-sketch-block img{
    width: 100%;
    border-radius: 0.8em;
}
.sketch-block{
    vertical-align: middle;
    margin: auto;
}
.ResearchD{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
}
.ResearchD img{
    width: 86%;
    text-align: center;
    margin: auto;
    margin-bottom: 80px;
}
@media screen and (max-width:1100px) {
    
}