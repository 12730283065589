/* NAVBAR */
.navbar-container{
    position: relative;
    display: flex;
    justify-content: space-between;
}
@media screen and (min-width:1600px){
    .navbar-container{
        top: 30px;
    }
}
.signature h2{
    display: flex;
    justify-content: start;
    font-family: "Rubik Mono One", monospace;
        color: transparent;
    background-image: 
        repeating-linear-gradient(
            -45deg, 
            #cedaeb,
            #cedaeb 2px,
            #8ea1ba 3px,
            #adb9ca 2px 
        );
    background-clip: text;
}
.menu{
    margin: 13.5px;
    padding: 10px 80px 8px 80px;
    gap: 60px;
    display: flex;
    right: 0;
    justify-content: end;
    vertical-align: middle;
    background-color: rgba(235, 235, 235, 0.94);
    border-radius: 50em;
    font-size: 15px;
}
.menu Link, .menu a{
    text-decoration: none;
    color: rgb(81, 81, 81);
}
.menu Link:hover, .menu a:hover{
    color: rgb(25, 94, 255);
    animation: jump 3s;
}
@keyframes jump {
    from{
        transform: translateX(-6%);
    } to {
        transform: translateX(6%);
    }
}
@keyframes jump2 {
    from{
        transform: translateX(-1.5%);
    } to {
        transform: translateX(0%);
    }
}
#nav{
    display: block;
}
.active{
    font-weight: 800;
}

/* BREADCRUMB */
.sections{
    display: flex;
    align-items: center;
    margin: auto;
    gap: 60px;
    padding: 10px 40px 10px 40px;
    border-radius: 50em;
    background-color: rgba(243, 243, 243, 0.89);
    z-index: 900;
}
#section_navigator{
    display: flex;
    position: fixed;
    justify-content: space-between;
    margin: auto 68px auto 68px; 
    right: 0px;
    left: 0px;
    z-index: 900;
}
#section{
    display: inline-flex;
    align-items: center;
    text-align: center;
    font-size: 15px;
    margin: auto;
}
#section:hover, #nav:hover{
    color: rgb(25, 94, 255);
    animation: jump 3s;
}
.menu-short{
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    background-color: rgba(243, 243, 243, 0.89);
    align-items: center;
    line-height: 18px;
    padding: 0 40px 0 40px;
    margin: 20px auto 20px auto;
    border-radius: 50em;
    font-size: 15px;
}

/* CARD */
/* CARD:FLOWIN */
.card-container:first-child .card-p-detail{
    text-align: left;
    position: absolute;
    margin: auto;
    transform: translate(-120%, 0%);
    z-index: 100;
    width: 260px;
    padding: 6px 30px 6px 30px;
    background: rgba(255,255,255,0.6);
    backdrop-filter: blur(3px);
    border-radius: 0.8em;
}
.card-container:not(:first-child) .card-p-detail{
    text-align: left;
    position: absolute;
    margin: auto;
    transform: translate(-120%, 0%);
    z-index: 100;
    width: 230px;
    padding: 6px 30px 6px 30px;
    background: rgba(255,255,255,0.4);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255,255,255,0.4);
    border-radius: 0.8em;
}
.card-container:first-child:hover .card-p-detail{
    transform: translate(-10%, 216%);
    animation: cardin1 2s;
}
.card-container:not(:first-child):hover .card-p-detail{
    transform: translate(-10%, 40%);
    animation: cardin2 2s;

}
@keyframes cardin1 {
    from {
        transform: translate(-120%, 216%);
    } to {
        transform: translate(-10%, 216%);
    }
}
@keyframes cardin2 {
    from {
        transform: translate(-120%, 40%);
    } to {
        transform: translate(-10%, 40%);
    }
}
/* CARD:IMG */
#project-thumbnail{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.card-container:first-child .img-container{
    position: relative;
    overflow: hidden;
    display: flex;
    width: 80%;
    max-width: 420px;
    max-width: 420px;
    height: auto;
    transform: translateX(5%);
}
.card-container:not(:first-child) .img-container{
    position: relative;
    overflow: hidden;
    display: flex;
    width: 56%;
    max-width: 420px;
    max-width: 420px;
    height: auto;
}

/* CARD:MAIN CARD */

.card-container{
    border-radius: 0.8em;
    padding: 24px;
    overflow: hidden;
    height: 230px;
    text-align: center;
}
.card-container:first-child{
/*
* Created with https://www.css-gradient.com
* Gradient link: https://www.css-gradient.com/?c1=6e72ff&c2=2476ff&gt=r&gd=dbr
*/
background: rgba(110, 114, 255, 1.0);
background: radial-gradient(at right bottom, rgba(110, 114, 255, 1.0), rgba(36, 118, 255, 1.0));
}
.card-container:nth-child(2){
/*
* Created with https://www.css-gradient.com
* Gradient link: https://www.css-gradient.com/?c1=fff51e&c2=acff32&gt=l&gd=dtl
*/
background: #FFF51E;
background: linear-gradient(135deg, #FFF51E, #ACFF32);
}
.card-wraper{
    text-decoration: none;
    color: #494949;
}
.card-title{
    display: flex;
    justify-content: space-between;
}
.card-title p{
    margin-top: 0;
}
#special-title{
    font-family: "Geologica", sans-serif;
}
.card-slide-in{
    margin: auto;
    background-color: rgba(235, 235, 235, 0.8);
    border-radius: 0.8em;
    padding: 6px 16px 6px 16px;
    width: 230px;
    z-index: 400;
}
.card-slide-in h3{
    padding-left: 10px;
    line-height:16px;
}
.card-slide-in p{
    font-size: 14px;
    padding-left: 10px;
}
.chips{
    display: flex;
    margin-top: -10px;
    gap: 6px;
    justify-content: start;
}
#chip{
    margin-top: 2px;
    background-color: white;
    padding: 2px 18px 2px 18px;
    font-size: 12px;
    border-radius: 50em;
}
#arrow{
    display: none;
}
.card-container:hover  #arrow path{
    animation: flying 3s linear infinite;
}
.card-container:hover .card-title{
    animation: fadeGone 2s;
    opacity: 0;
}
.card-container:first-child{
    grid-column: 1/3;
    grid-row: 1/3;
    height: 550px;
}
.card-container:nth-child(2), .card-container:nth-child(3){
    grid-column: 3/-1;
}
@keyframes flying {
    from {
        transform: translate(-30px, 30px)
    }
    to {
        transform: translate(30px, -30px)
    }
}

.card-container:not(:first-child):hover  .card-slide-in{
    animation: slide-in 0.8s linear;
    transform: translate(0%, -286%);
    z-index: 100;
    opacity: 1;
}
.card-container:hover #arrow{
    display: flex;
    justify-content: end;
    background-color: rgb(147, 147, 147);
    padding: 6px;
    border-radius: 50em;
    fill: white;
}
.card-container:first-child:hover  .card-slide-in{

    /* animation: slide-in2 0.4s linear; */
    /* transform: translateX(-10%);
    transform: translateY(-70%); */
}
.img-container h2{
    display: none;
}
#project-banner-img:first-child{
    position: relative;
    display: flex;
    text-align: center;
    vertical-align: middle;
    margin: 4% auto auto auto;
    padding: auto;
    z-index: 30;
}
#project-banner-img:not(:first-child){
    margin-top: -20px;
}
@media screen and (min-width:1600px) {
    #project-banner-img:first-child{
        margin: -4% auto auto auto;
    }
}
.card-container:hover #bgText{
    animation: slideup 2s;
    display: flex;
    position: absolute;
    z-index: 20;
    transform: translateX(146%);
    transform: translateY(-36%);
    margin: auto;
    animation: textup 2s;
    opacity: 0.2;
}
.card-container:not(:first-child):hover #bgText{
    animation: slideup 2s;
    display: flex;
    position: absolute;
    z-index: 20;
    transform: translateX(146%);
    transform: translateY(-50%);
    margin: auto;
    animation: textup 2s;
    opacity: 0.2;
}
.card-container:first-child:hover #project-banner-img{
    display: flex;
    position: relative;
    transform: translateY(10%);
    animation: slidedown 2s;
}
.card-container:not(:first-child):hover #project-banner-img{
    display: flex;
    position: relative;
    transform: translate(40%, -10%);
    animation: slideside 2s;
    z-index: 500;
}
@keyframes textup {
    from {
        opacity: 0;
    } to {
        opacity: 0.2;
    }
}
@keyframes slideup {
    from {
        transform: translate(0%, 0%)
    } to {
        transform: translate(-10%, -10%)
    }
}
@keyframes slidedown {
    from {
        transform: translateY(0);
    } to {
        transform: translateY(10%);
    }
}
@keyframes slideside {
    from {
        transform: translate(0, 0)
    } to {
        transform: translate(40%, -10%)
    }
}
@keyframes slide-in {
    from {
        transform: translate(0%, -206%);
    }
    to {
        transform: translate(0%, -286%);
    }
}
@keyframes slide-in2 {
    from {
        opacity: 0;
        transform: translateY(10%);
    }
    to {
        opacity: 1;
        transform: translateY(-73%);
    }
}

/* FOOTER */

.footer-desktop{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 120px auto 20px auto;
}
.footer-container-mb{
    display: none;
}
.ft-l{
    display: block;
    justify-content: start;
}
.ft-m{
    text-align: right;
}

.ft-r{
    text-align: right;
    line-height: 8px;
}
.button-standard{
    display: inline-block;
    padding: 0 16px 0 16px;
    background-color: #494949;
    line-height: 30px;
    margin: 12px 2px 12px 2px;
    text-decoration: none;
    border-radius: 50em;
    color: white;
    font-size: 15px;
}
.line{
    border-bottom: #494949 2px solid;
    margin: -6px 0 -6px 0;
}
.card-container:hover, .button-standard:hover {
    box-shadow:  10px 20px 20px #f0f0f0 ;
}
.card-container:hover{
    outline: #ffffff 14px solid;
}
.button-standard:hover {
    box-shadow:  10px 20px 20px #f0f0f0 ;
    animation: jump2 2s;
}
.connect{
    display:'flex'; 
    gap:'10px'; 
    justify-content:'end'
}
/* BANNER */
body#banner{
    margin: -20px -138px 0px -138px;
    position: relative;
}
.top-container{
    padding-top: 20px;
    margin: auto 138px auto 138px;
    position: relative;
    overflow: hidden;

}
#banner-product_mb {
    display:  none;
}
@keyframes jump-in {
    from{
        transform: translateY(-5%);
    }
    to{
        transform: translateY(0%);
    }
}
@keyframes fade-opa {
    from{
        transform: opacity(0.7);
    }
    to{
        transform: opacity(1);
    }
}
.project-header-title{
    vertical-align: middle;
    margin: auto;
    font-size: 80px;
    font-weight: 600;
    color: #494949;
    line-height: 90px;
}
.project-header-headline{
    font-size: 22px;
    line-height: 38px;
    font-weight: 400;
    color: #494949;
    margin: 20px 0 40px 0;
}
.hero-banner-wraper{
    position: relative;
    margin-top: 180px;
}
.text-wrapper-project{
    margin-top: 160px;
    margin-bottom: 80px;
    text-align: left;
    color: rgb(147, 147, 147);
    width: 80%;
    animation: aLittleJump 2s;
}
@keyframes aLittleJump {
    from {
        transform: translateY(-12%);
    } to {
        transform: translateY(0);
    }
}
@media screen and (min-width:1600px) {
    .text-wrapper-project{
        margin-top: 180px;
    }
}
.banner-products{
    z-index: 90;
    position: fixed;
    top: 180px;
    right: 0;
    margin: -50px 0 0 0;
    padding: 60px 138px 60px 138px;
    background-image: linear-gradient(90deg, rgba(0,106,255,1) 0%, rgba(248,243,255,1) 59%, rgba(255,255,255,1) 100%);
    width: 30%;
    transition: right 1s ease-in-out;
}
#banner-product{
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0px auto 0 20px;
    width: 100%;
    z-index: 90;
    animation: fade-opa 3s;
}
#rotate{
    position: absolute;
    margin: 80px 0 0 160px;
    scale: 1.1;
    animation: spin 30s infinite;
}
@keyframes spin {
    from{
        transform: rotate(0);
    }to{
        transform: rotate(-360deg);
    }
}
text{
    border: rgb(147, 147, 147) 1px;
}
.bannerTags{
    display: flex;
    margin: auto auto 40px auto;
    gap: 10px;
    left: 50%;
    flex-direction: row;
    flex-wrap: wrap;
}
.bannerTag{
    padding: 6px 18px 6px 18px;
    background-color: rgba(79, 132, 231, 0.1);
    color: rgb(80, 80, 80);
    border-radius: 50em;
    font-size: 12px;
}
.project-navs{
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    gap: 60px;
}
.project-nav, #project-nav-link{
    background-color: #494949;
    color: rgb(255, 255, 255);
    line-height: 18px;
    border-radius: 0.8em;
}
.project-nav{
    position: relative;
    padding: 40px;
    height: 140px;
    font-size: 24px;
    line-height: 28px;
}
.project-nav h3{
    position: absolute;
    top: 0px;
    left: 30px;
    font-weight: 600;
}
.project-nav p{
    position: absolute;
    bottom: 0px;
    left: 30px;
}
.project-nav:hover, .project-nav:hover #project-nav-link, .project-nav:hover .project-nav h3{
    background-color: #cfcfcf;
    color: rgb(0, 98, 255);
}
.project-nav:hover{
    outline: #ffffff 12px solid;
    box-shadow:  10px 20px 20px #f3f3f3 ;
}
.cookie-popup-contianer{
    position: fixed;
    margin: auto;
    bottom: 0;
    left: 0;
    z-index: 900;
    background-color: rgba(246, 246, 246, 0.94);
    padding: 6px;
    width: 100%;
}
.notice{
    display: flex;
    vertical-align: middle;
    margin: auto;
    justify-content: space-between;
}
.notice-l{
    /* border: red 2px solid; */
    margin-left: 138px;
    display: flex;
}
.notice-l img{
    width: 4.2%;
}
.notice-r{
    /* border: red 2px solid; */
    display: flex;
    gap: 12px;
    margin: auto 138px auto auto ;
    vertical-align: middle;
    justify-content: end;
}
.notice p{
    color: #494949;
    font-size: 14px;
}
#allow{
    border-radius: 100em;
    background-color: #0966FF;
    color: white;
    padding: 5px 20px 5px 20px;
    font-size: 14px;
    vertical-align: middle;
    margin: auto;
}
#close{
    border: #696969 1.8px solid;
    border-radius: 100em;
    padding: 3.6px 20px 3.6px 20px;
    font-size: 14px;
    vertical-align: middle;
    margin: auto;
}
#allow:active ~ .notice-l{
    background-color: #ff9809;
}
@media screen and (min-width:1600px) {
    #banner-product{
        width: 88%;
        margin: 0px auto 0 20px;
    }
    #rotate{
        position: absolute;
        margin: 80px 0 0 160px;
    }
    #project-banner-img{
        scale: 0.8;
    }
    .card-container:hover .img-container h2{
        transform: translateY(-36%);
    }
}
@media screen and (max-width:1100px) {
    body{
        margin: 24px 48px auto 48px;
    }
    /* NAVBAR */
    Link, a{
        font-size: 14px;
    }
    #nav:nth-child(2), #nav:nth-child(3){
        display: none;
    }
    .menu{
        margin: auto 0 auto auto;
        padding: 8px 32px 8px 32px;
        gap: 42px;
    }
    /* CARD */
    .card-container{
        margin-top: 40px;
        margin-left: -24px;
        margin-right: -24px;
    }
    /* FOOTER */
    footer{
        position: relative;
        display: block;
        margin: 16px;
    }
    .footer-desktop{
        display: none;
    }
    .footer-container-mb{
        display: block;
        padding: 12px;
        position: relative;
        background-color: #cccccc;
        margin: 60px -20px -20px -20px;
        border-radius: 0.8em 0.8em 0 0;
    }
    .ft-l{
        display: none;
    }
    .ft-m{
        text-align: center;
    }
    .ft-r{
        text-align: center;
        line-height: 8px;
    }
    /* BANNER */
    body#banner{
        margin: -30px -60px 0px -60px;
        padding-top: 10px;
    }
    .top-container{
        padding-top: 20px;
        margin: auto 60px auto 60px;
        overflow: hidden;
    }
    .hero-banner-container{
        margin: auto 42px auto 42px;
        margin: 60px 0 0 0;
        grid-template-columns: 1fr;
        padding-bottom: 0px;
    }
    .text-wrapper{
        margin: auto;
    }
    #banner-product{
        display: none;
    }
    #banner-product_mb{
        display: block;
        text-align: center;
        margin: auto auto auto auto;
        width: 90%;
    }
    #banner-images{
        background: none;
    }
    #rotate{
        display: none;
    }
    .project-header-title{
        font-size: 42px;
        font-weight: bold;
        text-align: center;
    }
    .project-header-headline{
        font-size: 16px;
        line-height: 22px;
        margin: 20px 0 30px 0;
    }
    .tags{
        display: none;
    }
    .bannerTags{
        justify-content: center;
        display: flex;
        margin: auto auto 40px auto;
        gap: 6px;
    }
    /* BREADCRUMB */
    #section_navigator{
        display: none;
    }
}