/* TOP PART */
.top-banner-container{
    margin: auto 138px auto 138px;
    height: auto;
}
.hero-banner-container{
    position: relative;
    display: grid;
    grid-template-columns: 1.6fr 1.3fr;
}
.home-cards-container{
    margin: 80px 0 80px 0;
}
.banner-container{
    margin: 100px -138px 160px -138px;
    min-height: 400px;
    position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr;
    overflow: hidden;
}
#brann_avartar_mb{
    display: none;
}
@media screen and (max-width:1100px){
    .mb-img{
        display: flex;
        justify-content: center;
    }
    #brann_avartar_mb{
        display: block;
        max-width: 280px;;
        margin: 80px 0 -80px 0;
    }
    .banner-container{
        margin-bottom: 0;
    }
    .text-wrapper{
        margin: auto;
    }
}
@media screen and (min-width:1800px) {
    .banner-container{
        margin: 100px -138px 80px -138px;
        height: 580px;
        position: relative;
        display: grid;
        grid-template-columns: 2fr 1fr;
        overflow: hidden;
    }
}
/* Entire Cards Layout */
.cards-container{
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 38px;
    width: 100%;
    /* border: 0.5px rgb(255, 0, 0) solid; */
}

@media screen and (max-width:1100px) {
    .before-text{
        position: absolute;
        display: block;
        text-align: left;
        padding-right: 10px;
        top: 0px;
        left: 0px;
        z-index: 400;
        opacity: 1;
    }
    #special-title{
        font-size: 18px;
    }
    .cards-container{
        display: block;
    }
    .home-card-container{
        margin-bottom: 30px;
        margin-right: 10px;
    }
    .title-wrapper{
        display: none;
    }
    .grouping{
        display: none;
    }
}
/* Cards Section Body */
.home-card-container{
    position: relative;
    /* border: 0.5px rgb(95, 95, 95) solid; */
    padding: 5vh;
    text-align: center;
    display: flex;
    justify-content: center;
}
.home-card-container:first-child{
    grid-column: 1/3;
    grid-row: 1/3;
    overflow: hidden;
}
.home-card-container:not(:first-child){
    max-height: 240px;
    overflow: hidden;
}


.home-card-container:nth-child(2){
    grid-column: 3/-1;
    grid-row: 1;
}
.home-card-container:nth-child(3){
    grid-column: 3/-1;
    grid-row: 2;
}
.home-card-container:nth-child(4), .home-card-container:nth-child(5), .home-card-container:nth-child(6), .home-card-container:nth-child(7){
    grid-row: 3;
}

/* Each Card Body */
.home-card-wraper{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 8%;
}
/* Each Image */
.home-card-container:not(:first-child) .h-c-img{
    /* border: 0.5px rgb(30, 79, 255) solid; */
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
    height: auto;
    max-width: 500px;
    max-height: 500px;
}
.home-card-container:first-child .h-c-img{
    /* border: 0.5px rgb(30, 79, 255) solid; */
    margin-top: 40px;
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
    height: auto;
    max-width: 480px;
    max-height: 480px;
}
.home-card-container:nth-child(3) .h-c-img{
    margin-top: 40px;
}
.home-card-container:nth-child(4) .h-c-img{
    margin-top: 40px;
}
.home-card-container:nth-child(5) .h-c-img{
    margin-top: 40px;
}
.home-card-container:nth-child(7) .h-c-img{
    margin-top: 40px;
    right: 26%;
}
.home-card-container:nth-child(7) #h-c-thumbnail{
    width: 210%;
    transform: translateX(-52%);
}
.home-card-container:nth-child(6) .h-c-img{
    margin-top: 40px;
    left: 26%;
}
.home-card-container:nth-child(6) #h-c-thumbnail{
    width: 200%;
    transform: translateX(0%);
}
.home-card-container:nth-child(n+4) #title-shown{
    font-size: 30px;
    line-height: 30px;
}
.home-card-container:nth-child(n+4) .ad-r-t{
    display: flex;
    flex-direction: column;
}
.home-card-container:nth-child(n+4) #animate-top{
    font-size: 24px;
}
.home-card-container:nth-child(n+4) #tags-main{
    display: none;
}
.home-card-container:nth-child(n+4) #des-2{
    margin-top: -6px;
    font-size: 16px;
}
.home-card-container:nth-child(1) #tags-sub, .home-card-container:nth-child(2) #tags-sub, .home-card-container:nth-child(3) #tags-sub{
    display: none;
}
.home-card-container:nth-child(n+4) #p-tag{
    margin: 0;
}
#des-2{
    font-size: 17px;
    line-height: 26px;
}
#tags-sub{
    display: flex;
    justify-content: start;
    left: 0;
    margin: 10px 0 0 0;
    gap: 6px;
}
#h-c-thumbnail{
    width: 100%;
    object-fit: contain;
    object-position: center;
    z-index: 20;
    filter: drop-shadow(4px 6px 6px rgb(60, 60, 60));
}
@media screen and (max-width:1100px) {
    .home-card-container:nth-child(3) .h-c-img{
        margin-top: 50px;
        width: 150%;
    }
}
/* HOVER IMAGE EFFECT */
.home-card-container:first-child:hover .h-c-img{
    margin-top: 6%;
    animation: imgMove1 2s;
}
.home-card-container:hover .h-c-img{
    margin-top: -10%;
    animation: imgMoveRest 2s;
}
.home-card-container:nth-child(3):hover .h-c-img{
    margin-top: -8%;
    animation: imgMove3 2s;
}
@keyframes imgMove1 {
    from{
        margin-top: 40px;
    } to {
        margin-top: 6%;
    }
}
@keyframes imgMoveRest {
    from{
        margin-top: 0;
    } to {
        margin-top: -10%;
    }
}
@keyframes imgMove3 {
    from{
        margin-top: 40px;
    } to {
        margin-top: -8%;
    }
}
@media screen and (max-width:900px) {
    #h-c-thumbnail{
        padding-top: 30px;
        width: 100%;
    }
    .home-card-container:nth-child(2)    #h-c-thumbnail{
        padding-top: 80px;
        width: 140%;
    }
    .home-card-container:nth-child(3)    #h-c-thumbnail{
        padding-bottom: 60px;
        width: 160%;
    }
}
@media screen and (min-width:1600px) {
    .home-card-container:not(:first-child){
        max-height: 240px;
    }
    .home-card-container:first-child .h-c-img{
        max-width: 500px;
        max-height: 520px;
    }
    .home-card-container:nth-child(2) .h-c-img{
        max-width: 580px;
        max-height: 580px;
    }
    .home-card-container:nth-child(6) #h-c-thumbnail{
        width: 200%;
        transform: translateX(0);
    }
    .home-card-container:nth-child(7) #h-c-thumbnail{
        width: 200%;
        transform: translateX(-50%);
    }
}
/* Front text */
.before-text{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    top: 12px;
    left: 20px;
    z-index: 400;
    opacity: 1;
}
#detail-shown{
    font-family: "Geologica", sans-serif;
    font-size: 18px;
    font-weight: 200;
    line-height: calc(18px*1.2);
    margin: 14px 0 0 0;
}
#field-shown{
    display: flex;
    color: #3A4459;
    font-family: "Geologica", sans-serif;
    font-size: 17px;
    font-weight: 300;
    line-height: 17px;
    margin: 14px 0 0 0;
}
#title-shown{
    display: flex;
    color: #3A4459;
    font-family: "Geologica", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
    margin: 0 0 0 0;
}
.home-card-container:hover .before-text{
    opacity: 0;
}
/* Background Text */
.home-card-container:first-child .home-card-wraper .background-text{
    transform: translateY(-200%);
}
.background-text{
    /* border: purple 2px dashed; */
    text-align: center;
    font-size: 800%;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    right: 6%;
    margin: auto;
    transform: translateY(120%);
    z-index: 1;
    opacity: 0;
}
.home-card-container:first-child:hover .home-card-wraper .background-text{
    transform: translateY(-40%);
    opacity: 0.05;
    animation: bgTextMove1 1.2s;
    z-index: -1;
}
.home-card-container:nth-child(-n + 3):hover .background-text{
    font-size: 1000%;
}
.home-card-container:nth-child(1):hover .background-text{
    font-size: 2000%;
}
.home-card-container:nth-child(2):hover .background-text, .home-card-container:nth-child(3):hover .background-text{
    transform: translateY(-52%);
    opacity: 0.05;
    animation: bgTextMove23 1.2s;
    z-index: -1;
}
.home-card-container:hover .background-text{
    transform: translateY(-53%);
    opacity: 0.05;
    animation: bgTextMoveRest 1.2s;
    z-index: 0;
}
@keyframes bgTextMove1 {
    from {
        transform: translateY(120%);
    } to {
        transform: translateY(-40%);
    }
}
@keyframes bgTextMove23 {
    from {
        transform: translateY(120%);
    } to {
        transform: translateY(-52%);
    }
}
@keyframes bgTextMoveRest {
    from {
        transform: translateY(120%);
    } to {
        transform: translateY(-53%);
    }
}
@media screen and (min-width:1800px) {
    .home-card-container:first-child .home-card-wraper .background-text{
        font-size: 1800%;
    }
    .background-text{
        margin: auto;
        font-size: 1200%;
    }
}

/* animate-mask */
.animate-detail-container{
    vertical-align: middle;
}
.animate-details-wrap{
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding-left: 24px;
    padding-right: 24px;
    font-family: "Geologica", sans-serif;
}
#animate-top{
    font-size: 36px;
    line-height: 42px;
    margin: 0 0 0 0;
}
#smaller-tag{
    display: flex;
    flex-direction: column;
}
.ad-r-t{
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
}
.a-d-right p{
    font-size: 16px;
    line-height: calc(16px*1.2);
    font-weight: 200;
    margin: 6px 0 0 0;
}
.a-d-right{
    display: flex;
    flex-direction: column;
    justify-content: end;
    text-align: left;
}
.project-tags{
    display: flex;
    gap: 6px;
}
#p-tag{
    font-size: 15px;
    background-color: rgba(250,250,250,0.95);
    color: #34363C;
    margin: auto;
    padding: 4px 16px 4px 16px;
    border-radius: 100em;
}
.home-card-container:hover .animate-object{
    bottom: 0;
    right: 0;
    left: 0;
    transform: translateY(0%);
    border-radius: 0.8em;
    animation: BGMoveUp 2s;
}
.home-card-container:first-child:hover .animate-object{
    height: 130px; 
}
.home-card-container:first-child:hover .h-c-img{
    scale: 1.12;
    z-index: 200;
    animation: scaleImg 2s;
}
@keyframes scaleImg {
    from {
        scale: 1;
    } to {
        scale: 1.12;
    }
}
.animate-object{
    /* border: rgb(255, 0, 0) 2px solid; */
    background-color: rgb(64, 64, 64);
    color: white;
    position: absolute;
    width: 100%;
    z-index: 300;
    height: 100px;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translateY(100%);
}
.home-card-container:nth-child(-n+3) .p-title-wraper{
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr;
    text-align: left;
    margin: 8px auto auto 30px;
    gap: 40px;
}
.home-card-container:nth-child(-n+3) .p-title-wraper .title-wraper{
    position: relative;
    display: flex;
    top: 0;
    left: 0;
}
.home-card-container:nth-child(1) #chip-bigguy{
    margin-top: 20px;
}
.home-card-container:nth-child(-n+3) #chip-smallguy{
    display: none;
}
.home-card-container:nth-child(-n+2) #animate-title{
    position: absolute;
    font-size: 50px;
    margin-top: 0px;
}
.home-card-container:nth-child(3) #animate-title{
    position: relative;
    font-size: 32px;
    margin-top: 14px;
    line-height: 34px;
}
.home-card-container:nth-child(n+4) .p-title-wraper{
    position: relative;
    display: block;
    text-align: center;
    margin: -16px auto auto auto;
}
.home-card-container:nth-child(n+4) .animate-object{
    height: 130px;
}
@media screen and (min-width:1600px) {
}
/* background */
.background{
    /* border: rgb(255, 0, 0) 2px solid; */
    border-radius: 0.8em;
    background-color: rgb(0, 251, 255);
    color: white;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
.arrow-wraper{
    position: absolute;
    display: flex;
    top: 5%;
    right: 5%;
}
#arrow1{
    scale: 2;
    opacity: 1;
}
/* HOVER BG EFFECT */
.home-card-container:first-child:hover .background{
    border-radius: 50em 50em 3em 3em;
    margin-top: 200px;
    animation: BGMoveDown1 2s;
}
.home-card-container:hover .background{
    border-radius: 50em 50em 5em 5em;
    margin-top: 90px;
    animation: BGMoveDown 2s;
}
@keyframes BGMoveDown1 {
    from{
        margin-top: 0;
        border-radius: 0;
    } to {
        margin-top: 200px;
        border-radius: 50em 50em 3em 3em;
    }
}
@keyframes BGMoveDown {
    from{
        margin-top: 0;
        border-radius: 0;
    } to {
        margin-top: 90px;
        border-radius: 50em 50em 5em 5em;
    }
}
@keyframes BGMoveUp {
    from{
        transform: translateY(100%);
        border-radius: 0em;
    } to {
        transform: translateY(0);
        border-radius: 0.8em;
    }
}
@media screen and (min-width:1800px) {
    .home-card-container:first-child:hover .background{
        border-radius: 50em 50em 3em 3em;
        margin-top: 260px;
        animation: BGMoveDown1 2s;
    }
    .home-card-container:hover .background{
        border-radius: 50em 50em 3em 3em;
        margin-top: 180px;
        animation: BGMoveDown 2s;
    }
    @keyframes BGMoveDown1 {
        from{
            margin-top: 0;
            border-radius: 0;
        } to {
            margin-top: 260px;
            border-radius: 50em 50em 3em 3em;
        }
    }
    @keyframes BGMoveDown {
        from{
            margin-top: 0;
            border-radius: 0;
        } to {
            margin-top: 180px;
            border-radius: 50em 50em 3em 3em;
        }
    }
}