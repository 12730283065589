.mobile{
    color: #565656;
}
.mb-header{
    position: relative;
    vertical-align: middle;
    text-align: center;
    padding: 16px;
    margin: auto;
}
.mb-tag{
    display: flex;
    gap: 3px;
}
#mobile-header-title{
    font-size: 46px;
    font-weight: 900;
}
.mobile p{
    font-size: 18px;
    line-height: 30px;
}
#mb-banner{
    position: relative;
    margin: 40px auto auto auto;
    z-index: 100;
}
.mb-project-bg{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: .8em;
    height: 200px;
}
#project-mockup{
    width: 100%;
    margin-top: 40px;
}
.mockup-sets h3{
    font-size: 28px;
}
